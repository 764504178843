<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title">Folio: {{folio}}</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Huerta</label>
              <md-input v-model="huerta" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Cliente</label>
              <md-input v-model="cliente" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Producto</label>
              <md-input v-model="producto" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Productor</label>
              <md-input v-model="productor" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Expedición</label>
              <md-input v-model="expedicion" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Resultado</label>
              <md-input v-model="resultado" disabled></md-input>
            </md-field>
          </div> 
          <div class="md-layout-item md-size-100">
            <iframe  ref="myIframe" style="border:1px solid #666CCC" :src="pdf_file" class="framePdfNew" type="application/pdf" scrolling="auto">
            </iframe>
          </div> 
        </div>
        </md-card-content>
        </md-card>
        </div>

      </div>
    </div>
</template>
  
  <script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    data() {
    return{
      huerta:"",
      pFolio: "",
      cliente:"",
      producto: "",
      productor: "",
      expedicion: "",
      resultado: "",
      folio: "",
      pdf_file:""
    };
  },
    components: {

    },
    methods: {
    fetch() {
      const instance = axios.create({
        baseURL: "https://api.teknolablaboratorio.com/",
        headers: {
          Authorization: "Bearer " + this.$route.params.folio
        }
      });
      instance
        .get("analisis/"+this.$route.params.folio)
        .then(res => {
          if (res.data.folio == null){
            this.folio = "El documento o estudio no fue generado por el laboratorio TEKNOLAB"
          }
          else
          {
            this.folio = res.data.folio;
            this.huerta= res.data.huerta;
            this.cliente= res.data.cliente;
            this.producto = res.data.producto;
            this.productor = res.data.productor;
            this.expedicion=  moment(res.data.expedicion).format("DD/MM/YYYY");
            if(res.data.tolerancia == null)
            {
              this.resultado = res.data.resultado; 
            }
            else
            {
              this.resultado = res.data.resultado +" - "+ res.data.tolerancia;
            }
            var url ="https://api.teknolablaboratorio.com/Archivos/"+res.data.fileName;
            this.pdf_file = "https://docs.google.com/viewerng/viewer?url="+url+"&embedded=true";
          }

        })
        .catch(err => {
          console.log(err);
       
        });
    },

    },
    mounted() {
      this.fetch();
  }
  };
  </script>
  