<template>
  <footer class="footer">
    <div class="container">
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="http://teknolab.com.mx/" target="_blank"
          >INTEGRADORA DE AGRONEGOCIOS VILLA DE TANCITARO, S.A. DE C.V.</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
