<template>
    <div class="content">
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title">Buscador de Informes Teknolab</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <img src="@/assets/img/indicacion.jpg">
          </div>                 
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Folio:</label>
              <md-input v-model="folio"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-button class="md-raised md-success" @click="fetch">Buscar</md-button>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Resultado:</label>
              <md-input v-model="resultado" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <iframe  ref="myIframe" style="border:1px solid #666CCC" :src="pdf_file" class="framePdfNew" type="application/pdf" scrolling="auto">
            </iframe>
          </div> 
        </div>
        </md-card-content>
        </md-card>
        </div>

      </div>
    </div>
</template>
  
  <script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    data() {
    return{
      folio: "",
      resultado: "",
      pdf_file:""
    };
  },
    components: {

    },
    methods: {
    fetch() {
      var url ="https://api.teknolablaboratorio.com/Archivos/"+this.folio+".pdf";
      if (this.fileExists(url)) 
      {
        this.resultado= "El archivo existe."
        this.pdf_file = "https://docs.google.com/viewerng/viewer?url="+url+"&embedded=true";
      } 
      else
      {
        this.resultado= "No se encontró el documento."
        this.pdf_file = "https://docs.google.com/viewerng/viewer?url="+url+"&embedded=true";
      }
    },
    fileExists(url) {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, false);
      xhr.send();
      return xhr.status != 404;
    }
    },
  };
  </script>
  