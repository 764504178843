import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Analysis from "@/pages/Analysis.vue";
import FindAnalysis from "@/pages/FindAnalysis.vue";
const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/analisis",
    children: [
      {
        path: "analisis/:folio",
        name: "",
        component: Analysis,
      },
      {
        path: "buscador",
        name: "",
        component: FindAnalysis,
      },
    ],
  },
];

export default routes;
